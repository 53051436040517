import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const data = [
  // OHIF v2 v3 parity
  {
    id: "OHIFParity",
    title: "OHIF v2/v3 Feature Parity",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          Our primary goal with OHIF v3 is to encourage as many users as we can
          to migrate off prior versions and to offer the most seamless possible
          migration path for this process. We understand that this means all key
          features and tools upon which the community has built such amazing
          work must be supported in v3 and have a clear migration path presented
          to developers. This remains a high priority for us until it is
          completed. As of version 3.1, Cornerstone3D is now provided in OHIF as
          a core Extension, allowing developers of Workflow Modes to leverage
          the capabilites of Cornerstone 3D while building their workflows.
          Cornerstone 3D includes a CPU-rendering fallback to encourage wide
          adoption, avoiding hardware requirements such as GPU workstations.
        </p>
        <p style={{ textIndent: 20 }}>
          We currently have a backlog of v2 features that are not available in
          OHIF v3. Our plan is to keep v3 off of the master branch until it can
          support all major v2 features, at which point we will move v3 to
          master so that it is easier for the community to focus on using one
          version. The full list of feature gaps is available in the{" "}
          <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
            <OutboundLink href="https://docs.ohif.org/release-notes#whats-new-in-ohif-v3">
              the v3 release notes
            </OutboundLink>
          </span>
          . We understand the highest priority missing features are DICOM
          Segmentation support, DICOM RT STRUCT support, and support for the
          multiplanar reformatting (MPR) views available in v2. We intend to
          implement each of these features using Cornerstone 3D, rather than
          legacy Cornerstone or react-vtkjs-viewport, as was done in the past.
          This will greatly reduce the GPU memory usage of the MPR viewports and
          improve the usability of the application as a whole.
        </p>
        <p style={{ textIndent: 20 }}>
          Once v3 is available on the master branch of the OHIF/Viewers
          repository, we will stop maintaining v2 moving forward. For a detailed
          introduction to v3, please see our presentation at the 36th National
          Alliance for Medical Image Computing (NAMIC) Project Week in January
          2022
        </p>
        <div class="aspect-w-16 aspect-h-10">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/668339696?h=63a2c48de8"
            width="640"
            height="400"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title: "DICOM Segmentation import and display",
        jiraLink: "jira.com"
      },
      {
        title: "Whole-Slide Image import and display",
        jiraLink: "jira.com"
      },
      {
        title:
          " Other features such as: Google Cloud Adapter, DICOM PDF, DICOM upload support, etc.",
        jiraLink: "jira.com"
      }
    ]
  },
  // CZI EOSS3 D&I
  {
    id: "CZIDI",
    title: "Chan Zuckerberg Initiative (CZI) EOSS3 Diversity & Inclusion (D&I)",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          Our CZI D&I project, which is funded for two years (2022-2023),
          addresses challenges of leadership progression for our diverse group
          of existing OHIF contributors, as well as engaging and training new
          software developers from underrepresented groups in technology.
        </p>
        <p style={{ textIndent: 20 }}>
          Firstly, a key issue we have identified is that, while our existing
          global user base is geographically and ethnically diverse, and we want
          to support the advancement of existing community developers, our team
          has not previously had enough resources to allocate time to mentor
          these contributors to progress into core maintainers. This is due
          partly to lack of funding for these activities, and partly to the
          large up-front cost and delayed return on time invested. Funding from
          this CZI diversity grant will broaden our community engagement
          capacity so that we can identify and mentor our contributors from
          historically underrepresented groups.
        </p>
        <p style={{ textIndent: 20 }}>
          Secondly, we are working with Hack.Diversity in Boston, an
          organization committed to training and providing opportunities to
          underrepresented groups in technology, to create career pathways into
          the life science community for their Fellows with software development
          backgrounds and life science interests. We are serving as curriculum
          designers and mentors, equipping their Fellows to contribute to
          open-source software by the end of their 5-month projects through
          deconstructed Hackathons. Hack.Diversity has catalyzed the careers of
          hundreds of Black and Latinx/e/a/o technology contributors across ~50
          companies in the Greater Boston region, including healthcare companies
          and providers.
        </p>
        <p style={{ textIndent: 20 }}>
          Our overarching goal with this project is to enhance diversity and
          inclusion of participants and contributors to our open-source
          scientific software, and we are grateful to the Chan Zuckerberg
          Initiative for supporting this rewarding and valuable work.
        </p>
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            width="1600"
            height="900"
            src="https://www.youtube.com/embed/ChaMQyb4XH8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            rel="0"
            modestbranding="1"
            allowFullScreen
          ></iframe>
        </div>
        <div className={"pl-6 "}>
          <ul className="text-blue-100 list-disc">
            <li className="">
              <OutboundLink href="https://chanzuckerberg.com/eoss/proposals/open-health-imaging-foundation-ohif-and-cornerstone-medical-web-viewer/">
                Read more on the CZI Homepage
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://www.hackdiversity.com/">
                Learn more about Hack.Diversity
              </OutboundLink>
            </li>
          </ul>
        </div>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title:
          "In bridging Fellows into jobs as software engineers, the Hack.Diversity curriculum includes a mini-project through which Fellows demonstrate their full-stack web development skill set. The current mini-project focuses on building a web application for tracking books at a community library. To date, projects have not exposed Fellows to the scientific software ecosystem or trained them in contributing to open-source software (OSS). The OHIF team will be responsible for designing and creating training materials and serving as primary tech instructor leads for Fellows throughout their three-phase project. We will provide Fellows with healthcare-based mini-projects and support them during their construction. The OHIF team will conduct interactive tech talks introducing Fellows into how the projects scaffold into larger OSS applications, and how to contribute to OSS as a community member.",
        jiraLink: "jira.com"
      },
      {
        title:
          'We will focus on supporting our global community by engaging its members beyond emails and forum messages. We plan to provide weekly "office hours" (on Zoom) for end users and contributors to meet and discuss concerns and questions with our core team, as well as scheduled contributor conference calls to discuss how to handle newly opened issues and pull requests. Through these meetings, we aim to identify contributors who show potential for progression towards leadership roles, and allocate time specifically to help them grow and teach others in the community.',
        jiraLink: "jira.com"
      }
    ]
  },
  // CZI EOSS3 D&I
  {
    id: "FUTURE",
    title: "Future Roadmap (UNFUNDED)",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          In the previous roadmap update, we listed many ideas and plans for
          OHIF which we did not have funding to support. We are happy to
          announce that two of the unfunded items are being implemented through
          the support of the community. Nevertheless, we still have more ideas
          to improve OHIF, and have listed some of our top priorities here with
          the hope that someone reading this page will have the resources and
          interest to support their development. If your team is interested in
          funding or co-funding the efforts to develop any of these features,
          please reach out to{" "}
          <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
            <OutboundLink href="mailto:contact@ohif.org">
              contact@ohif.org
            </OutboundLink>
          </span>
          .
        </p>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title: (
          <p>
            Multiframe DICOM Support is currently missing from OHIF which
            prevents us from visualizing many images. We intend to add support
            in both Cornerstone3D and OHIF for multiframe DICOM datasets.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Polymorph Segmentation Representation is a concept demonstrated by
            the{" "}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://www.slicer.org/">
                3D Slicer
              </OutboundLink>
            </span>{" "}
            team in which segmentations can be automatically converted between
            representations (e.g. planar contours, closed surface, labelmap) as
            needed to support the user's desired workflow. An implementation of
            this can be found in the{" "}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://github.com/PerkLab/PolySeg">
                PolySeg
              </OutboundLink>
            </span>{" "}
            library, and more information can be found in their{" "}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://doi.org/10.1016/j.cmpb.2019.02.011">
                publication in Computer Methods and Programs in Biomedicine by
                Csaba Pinter et al.
              </OutboundLink>
            </span>{" "}
            We believe this concept should be integrated into Cornerstone at the
            library level so that it is simple to view and edit segmentations as
            labelmaps, contours, closed surfaces without maintaining independent
            representations of each segment.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Contour Editing Tools - Our goal is to provide users with the most
            intuitive segmentation tools possible. Many commercial packages
            offer clinicians contour-based editing tools, rather than pixel-wise
            paintbrushes, because they can be easier to use require less manual
            interaction. We would like to develop a set of reusable interactions
            for contours, such as livewire editing and interpolating across
            slices. Aspects of these tools have been developed in other
            applications previously (i.e. XNAT-OHIF has linear interpolation
            across slices), but there is considerable room for improvement. We
            have recently added "push-in/push-out" style editing, and
            interpolating planar points into splines.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Cornerstone3D supports vtk.js 3D actors but does not have the
            ability to easily leverage vtk.js widgets (e.g. the
            ImageCroppingWidget), which may be a valuable addition to the
            framework.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Multivolume Volume Rendering: Currently, the WebGL pathway for
            VTK.js Volume Rendering, which is used by Cornerstone3D, renders
            each individual volume independently, and then overlays the
            resulting image from each pass. {""}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://projectweek.na-mic.org/PW33_2020_GranCanaria/Projects/VTKjsMultiVolumeRendering/">
                Previous work on this topic with the Isomics and Kitware teams
                was performed at the 33rd NAMIC Project Week in 2020.
              </OutboundLink>
            </span>{" "}
            {""}
            At the time, only partly funded so work was never completed,
            although it was nearly finished. The new WebGPU path may already
            support multiple volumes but this is not widely available yet in
            browsers, so we intend to support it in the WebGL path as well. This
            will enable displaying a segmentation inside e.g. a MIP view of a
            volume. This is critically important for visualizing results in a
            PET/CT workflow, for example.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Tool State Undo / Redo - Until now, annotation state history only
            existed in CornerstoneTools for labelmaps, despite it being a very
            common request from end users. We want to add this at the library
            level so the developer can easily undo/redo changes in the tool
            state.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            The IHE Key Image Notes profile is used to store notes regarding
            images and passing them between physicians. We intend to support
            DICOM Key Object Selection datasets and allow users to draft, store,
            review, and navigate between notes in a study.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            DICOM Grayscale Presentation State (GSPS) objects are used to store
            annotations and presentational information about images, such as
            important window/level settings, zoom and pan settings, etc. We
            intend to support at least basic usage (annotations, displayed area)
            for displaying stored GSPS objects, as this is a frequently
            requested feature.
          </p>
        ),
        jiraLink: "jira.com"
      }
    ]
  }
];

export default data;
