import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

function SideNav({ props, className = "", itemClassName = "" }) {
  if (!props) return null;

  const location = useLocation();

  const underLineProps = "border-b border-indigo-300";

  return (
    <div className={`${className} text-[18px] lg:text-[20px]`}>
      {props.map(({ title, link }) => (
        <div className={`${itemClassName}`} key={link}>
          <Link
            to={link}
            className={`text-indigo-300 ${
              location.pathname.startsWith(link) ? underLineProps : ""
            }`}
          >
            {title}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default SideNav;
