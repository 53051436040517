import React from "react";
import { Link, navigate } from "gatsby";
// import allProjects from "../../images/nav-projects-all.svg";
import navPrev from "../images/nav-prev.png";
import navNext from "../images/nav-next.png";

function InlineNav({ base, prev, next, current }) {
  return (
    <div className="mx-auto flex flex-col items-center">
      <div className="flex items-center gap-x-4 mb-2">
        <button
          className="flex items-center gap-2"
          disabled={!prev.id}
          onClick={() => navigate(`/${base}/${prev.id}`)}
        >
          <img
            src={navPrev}
            alt="prev"
            style={{ opacity: !prev.id ? 0.5 : 1 }}
          />
          <span style={{ opacity: !prev.id ? 0.5 : 1 }}>Prev</span>
        </button>
        <button
          className="flex items-center gap-2"
          disabled={!next.id}
          onClick={() => navigate(`/${base}/${next.id}`)}
        >
          <img
            src={navNext}
            alt="next"
            style={{ opacity: !next.id ? 0.5 : 1 }}
          />
          <span style={{ opacity: !next.id ? 0.5 : 1 }}>Next</span>
        </button>
      </div>
      {current}
    </div>
  );
}

export default InlineNav;
