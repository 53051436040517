import React, { useState } from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import GithubLogo from "./GithubLogo";
import WithAnchorLink from "./WithAnchorLink";

const getGithubAndJiraLinks = (item) => {
  return (
    <div className="flex items-center space-x-2">
      <div>
        <OutboundLink
          href={item.githubLink}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1"
        >
          <GithubLogo />
          <span>Github</span>
        </OutboundLink>
      </div>
      <div>
        <OutboundLink
          href={item.jiraLink}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1"
        >
          <div className="w-5 h-5">
            <StaticImage src="../images/jira-icon.png" alt="jira" />
          </div>
          <span>Jira</span>
        </OutboundLink>
      </div>
    </div>
  );
};

function ClosedAccordionItem({
  item,
  openStatus,
  onClick,
  onLinkClick,
  basePath
}) {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="flex items-center justify-between w-full min-h-full px-3 py-4 bg-blue-700 cursor-pointer"
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center w-full" id={item.id}>
        <div className="flex flex-col">
          <div className="flex items-center">
            <div className="w-8 h-8 mr-2">
              {openStatus ? (
                <StaticImage src="../images/chevron-down.png" alt="" />
              ) : (
                <StaticImage src="../images/chevron-right.png" alt="" />
              )}
            </div>
            <span className="text-aqua-bright text-[20px]">{item.title}</span>
          </div>
          {item.summary && (
            <span className="text-white text-[15px] pl-10 pt-2">
              {item.summary()}
            </span>
          )}
        </div>
        {/* {hover && (
          <div className="w-auto ml-2 text-lg">
            <WithAnchorLink
              link={`/${basePath}#${item.id}`}
              onClickHandler={onLinkClick}
            />
          </div>
        )} */}
      </div>
      {/* <div>{getGithubAndJiraLinks(item)}</div> */}
    </div>
  );
}

function OpenAccordionItem({ item }) {
  return (
    <div className="relative h-auto pb-8 pl-12 pr-10 text-base font-light transition-all duration-700 bg-blue-700">
      {typeof item.description === "function"
        ? item.description()
        : item.description}
      {item.components?.length ? (
        <h3 className="mt-4 font-medium text-blue-100">Components</h3>
      ) : null}
      {item.components?.map((component, index) => (
        <div key={index} className="flex items-start mt-4 ml-1">
          <div className="w-2 h-2 mt-2 mr-1">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" fill="#5acce6" />
            </svg>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="ml-2">{component.title}</div>
            {/* <OutboundLink
              href={component.jiraLink}
              target="_blank"
              rel="noreferrer"
              className="flex items-center space-x-1"
            >
              <div className="w-4 h-4">
                <StaticImage src="../images/jira-icon.png" />
              </div>
              <span className="font-light">Jira</span>
            </OutboundLink> */}
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesAccordion({ data, location, basePath }) {
  const [openedComponents, setOpenedComponents] = useState(() => {
    if (location.hash) {
      const roadmapId = location.hash.replace("#", "");
      return [roadmapId];
    } else {
      return [];
    }
  });

  const toggle = (id) => {
    const newOpenedComponents = [...openedComponents];
    if (newOpenedComponents.includes(id)) {
      newOpenedComponents.splice(newOpenedComponents.indexOf(id), 1);
    } else {
      newOpenedComponents.push(id);
    }
    setOpenedComponents(newOpenedComponents);
  };

  return (
    <div className="flex justify-center align-middle wrapper">
      <div className="w-full">
        {data.map((item, index) => (
          <div key={index} className="item text-bg-hover-blue">
            <div className="border-b-8 border-blue-800">
              <ClosedAccordionItem
                item={item}
                basePath={basePath}
                key={index}
                openStatus={openedComponents.includes(item.id)}
                onClick={() => toggle(item.id)}
                onLinkClick={() => {
                  // add the item.id to the opened components
                  setOpenedComponents([...openedComponents, item.id]);
                }}
              />
              {openedComponents.includes(item.id) && (
                <OpenAccordionItem item={item} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

FeaturesAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      githubLink: PropTypes.string.isRequired,
      jiraLink: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType
      ]).isRequired,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      components: PropTypes.arrayOf(
        PropTypes.shape({
          componentTitle: PropTypes.string,
          jiraLink: PropTypes.string
        })
      )
    })
  ).isRequired
};

export default FeaturesAccordion;
