import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";

const data = [
  // Cornerstone 3D alpha
  {
    id: "Cornerstone3DAlpha",
    title: "Cornerstone 3D Alpha Release",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          One of the core drawbacks of Cornerstone.js has historically been that
          it is a 2D-focused library, in which the core representation of data
          is a 2D image. While this framework has been used to build many great
          products and is lightweight enough to use on low-powered devices and
          devices without GPUs, it prevents us from addressing many imaging use
          cases that require advanced visualization techniques such as image
          fusion or multiplanar reformatting. A long term aim for the OHIF team
          has been to design and implement a successor to Cornerstone which
          supports its current functionality (rendering, tools, synchronizers,
          etc..) and is capable of displaying more complex datasets in each
          viewport.
        </p>
        <p style={{ textIndent: 20 }}>
          Many excellent web-based 3D imaging frameworks have been developed to
          date (XTK, Three.js based frameworks such as AMI, Med3Web, and
          VTK.js), but a common challenge is that when implementing these at the
          application-level with real-world imaging studies, it becomes
          impractical to automatically treat all incoming datasets as volumetric
          arrays. For example, if a CT study is loaded with 20 series, the
          browser will not be able to fit every series into memory
          simultaneously if they are immediately treated as volumes. Thus, the
          application is required to maintain both slice-based (or lower
          resolution volume-based) representations of each dataset and only
          produce the full volume when it is intended to be displayed.
          Addressing this issue was an additional requirement for our successor
          to Cornerstone.
        </p>
        <p style={{ textIndent: 20 }}>
          In December 2020, we entered into an academic-industry collaboration
          to co-fund new development of these next generation imaging libraries.
          The aim of the project was to build a harmonized library for 2D & 3D
          data visualization, with a target application of multi-modality
          (PET/CT) fusion display. The new library, written in TypeScript,
          leverages VTK.js for its core slice and volume rendering pipelines,
          and new viewport types can be defined as needed (e.g. for video
          support). The tool layer sits atop the viewport, and the annotation
          tools have been rewritten to render using SVG, rather than HTML5
          Canvas, to allow them to be re-rendered only when strictly necessary.
          The core library also includes APIs for caching/de-caching from slices
          to/from volumes, which can be called if the application will soon
          breach its memory limit, and can progressively stream slices into the
          viewer while the user is interacting with the viewport. In addition,
          an implementation of the logic defined by the Quantitative Imaging
          Biomarkers Alliance (QIBA) for computing Standardized Uptake Value
          (SUV) scaling factors across different vendor's PET datasets has been
          developed as an independent TypeScript library.
        </p>
        <div class="aspect-w-16 aspect-h-10">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/673435076?h=72d8728d9f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="640"
            height="400"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <p style={{ textIndent: 20 }}>
          These new libraries will be released to open-source in 2022. We aim to
          obtain feedback from the community about the new functionality and
          API, as well as thoughts about how we should support the new libraries
          and "legacy" libraries in the future. We are grateful to our industry
          partner for their assistance developing these libraries and agreeing
          to release them under a delayed-open-source model. Ongoing work on
          these libraries, in collaboration with The Lymphoma Academic Research
          Organisation (LYSARC), is focused on integrating segmentation tools.
        </p>
        <p style={{ textIndent: 20 }}>
          We view this partnership project as a template for future
          collaborations. If your team is interested in discussing such a
          collaboration, don't hestitate to reach out to{" "}
          <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
            <OutboundLink href="mailto:contact@ohif.org">
              contact@ohif.org
            </OutboundLink>
          </span>
          .
        </p>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      // {
      //   title:
      //     " Cornerstone-3D-render: The main library that will be used to render various kinds of viewports",
      //   jiraLink: "jira.com",
      // },
      // {
      //   title:
      //     "Cornerstone-3D-tools: For creating a variety of annotation tools, segmentations, and synchronizers",
      //   jiraLink: "jira.com",
      // },
      // {
      //   title:
      //     "Cornerstone-Streaming-Image-Volume: For streaming the image volume to the viewport as the data arrives",
      //   jiraLink: "jira.com",
      // },
    ]
  },
  // OHIF v2 v3 parity
  {
    id: "OHIFParity",
    title: "OHIF v2/v3 Feature Parity",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          Our primary goal with OHIF v3 is to encourage as many users as we can
          to migrate off prior versions and to offer the most seamless possible
          migration path for this process. We understand that this means all key
          features and tools upon which the community has built such amazing
          work must be supported in v3 and have a clear migration path presented
          to developers. This remains a high priority for us until it is
          completed. The next generation of Cornerstone will be provided in OHIF
          as an Extension, allowing developers of Workflow Modes to choose
          legacy Cornerstone or Cornerstone 3D while building their workflows.
          We do not plan to support mixing legacy Cornerstone and Cornerstone 3D
          in the same workflow, as they both maintain independent image data
          caches, however Cornerstone 3D will include a CPU-render fallback to
          encourage wider adoption without system specification limitations such
          as GPU workstations.
        </p>
        <p style={{ textIndent: 20 }}>
          We currently have a backlog of v2 features that are not available in
          OHIF v3. Our plan is to keep v3 off of the master branch until it can
          support all major v2 features, at which point we will move v3 to
          master so that it is easier for the community to focus on using one
          version. The full list of feature gaps is available in the{" "}
          <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
            <OutboundLink href="https://docs.ohif.org/release-notes#whats-new-in-ohif-v3">
              the v3 release notes
            </OutboundLink>
          </span>
          . We understand the highest priority missing features are DICOM
          Segmentation support, DICOM RT STRUCT support, and support for the
          multiplanar reformatting (MPR) views available in v2. We intend to
          implement each of these features using Cornerstone 3D, rather than
          legacy Cornerstone or react-vtkjs-viewport, as was done in the past.
          This will greatly reduce the GPU memory usage of the MPR viewports and
          improve the usability of the application as a whole.
        </p>
        <p style={{ textIndent: 20 }}>
          Once v3 is available on the master branch of the OHIF/Viewers
          repository, we will stop maintaining v2 moving forward. For a detailed
          introduction to v3, please see our presentation at the 36th National
          Alliance for Medical Image Computing (NAMIC) Project Week in January
          2022
        </p>
        <div class="aspect-w-16 aspect-h-10">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/668339696?h=63a2c48de8"
            width="640"
            height="400"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title: "DICOM Segmentation import and display",
        jiraLink: "jira.com"
      },
      {
        title: "RT STRUCT import and display",
        jiraLink: "jira.com"
      },
      {
        title: "Whole-Slide Image import and display",
        jiraLink: "jira.com"
      },
      {
        title:
          " Other features such as: Google Cloud Adapter, DICOM PDF, DICOM upload support, etc.",
        jiraLink: "jira.com"
      }
    ]
  },
  // CZI EOSS 3.0
  {
    id: "CZIEOSS3",
    title: "Chan Zuckerberg Initiative (CZI) EOSS3",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          Through our 2021 CZI EOSS3 grant, we aimed to improve 1) the size of
          the end-user and developer communities, 2) the ease of adoption and
          contribution, and 3) the level of transparency with the community and
          responsiveness from the core team. Below you can see a mockup of the
          workflow mode gallery that will be released in 2022 to support custom
          workflows within OHIF.
        </p>
        <StaticImage
          src="../images/roadmap-eoss3-workflow-mode-gallery@2x.png"
          alt="OHIF Workflow Mode Gallery Mockup"
        />
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title: "Newsletter and blog for proactive community communication",
        jiraLink: "jira.com"
      },
      {
        title: "Tutorials for common end-user tasks",
        jiraLink: "jira.com"
      },
      {
        title:
          "End-to-end test suite covering functionality present in documentation",
        jiraLink: "jira.com"
      },
      {
        title: "Guides for workflow and extension development",
        jiraLink: "jira.com"
      },
      {
        title:
          "Gallery to allow users to share their workflows with the community",
        jiraLink: "jira.com"
      },
      {
        title:
          "Improvements to developer-facing documentation for Cornerstone and OHIF",
        jiraLink: "jira.com"
      }
    ]
  },
  // CZI EOSS3 D&I
  {
    id: "CZIDI",
    title: "Chan Zuckerberg Initiative (CZI) EOSS3 Diversity & Inclusion (D&I)",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          Our CZI D&I project, which is funded for two years (2022-2023),
          addresses challenges of leadership progression for our diverse group
          of existing OHIF contributors, as well as engaging and training new
          software developers from underrepresented groups in technology.
        </p>
        <p style={{ textIndent: 20 }}>
          Firstly, a key issue we have identified is that, while our existing
          global user base is geographically and ethnically diverse, and we want
          to support the advancement of existing community developers, our team
          has not previously had enough resources to allocate time to mentor
          these contributors to progress into core maintainers. This is due
          partly to lack of funding for these activities, and partly to the
          large up-front cost and delayed return on time invested. Funding from
          this CZI diversity grant will broaden our community engagement
          capacity so that we can identify and mentor our contributors from
          historically underrepresented groups.
        </p>
        <p style={{ textIndent: 20 }}>
          Secondly, we will work with Hack.Diversity in Boston, an organization
          committed to training and providing opportunities to underrepresented
          groups in technology, to create career pathways into the life science
          community for their Fellows with software development backgrounds and
          life science interests. We will serve as curriculum designers and
          mentors, equipping their Fellows to contribute to open-source software
          by the end of their 5-month projects through deconstructed Hackathons.
          By the end of 2021, Hack.Diversity will have catalyzed the careers of
          ~250 Black and Latinx/e/a/o technology contributors across ~50
          companies in the Greater Boston region, including healthcare companies
          and providers.
        </p>
        <p style={{ textIndent: 20 }}>
          Our overarching goal with this project is to enhance diversity and
          inclusion of participants and contributors to our open-source
          scientific software, and we are grateful to the Chan Zuckerberg
          Initiative for supporting this rewarding and valuable work.
        </p>
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            width="1600"
            height="900"
            src="https://www.youtube.com/embed/ChaMQyb4XH8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            rel="0"
            modestbranding="1"
            allowFullScreen
          ></iframe>
        </div>
        <div className={"pl-6 "}>
          <ul className="text-blue-100 list-disc">
            <li className="">
              <OutboundLink href="https://chanzuckerberg.com/eoss/proposals/open-health-imaging-foundation-ohif-and-cornerstone-medical-web-viewer/">
                Read more on the CZI Homepage
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://www.hackdiversity.com/">
                Learn more about Hack.Diversity
              </OutboundLink>
            </li>
          </ul>
        </div>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title:
          "In bridging Fellows into jobs as software engineers, the Hack.Diversity curriculum includes a mini-project through which Fellows demonstrate their full-stack web development skill set. The current mini-project focuses on building a web application for tracking books at a community library. To date, projects have not exposed Fellows to the scientific software ecosystem or trained them in contributing to open-source software (OSS). The OHIF team will be responsible for designing and creating training materials and serving as primary tech instructor leads for Fellows throughout their three-phase project. We will provide Fellows with healthcare-based mini-projects and support them during their construction. The OHIF team will conduct interactive tech talks introducing Fellows into how the projects scaffold into larger OSS applications, and how to contribute to OSS as a community member.",
        jiraLink: "jira.com"
      },
      {
        title:
          'We will focus on supporting our global community by engaging its members beyond emails and forum messages. We plan to provide weekly "office hours" (on Zoom) for end users and contributors to meet and discuss concerns and questions with our core team, as well as scheduled contributor conference calls to discuss how to handle newly opened issues and pull requests. Through these meetings, we aim to identify contributors who show potential for progression towards leadership roles, and allocate time specifically to help them grow and teach others in the community.',
        jiraLink: "jira.com"
      }
    ]
  },
  // CZI EOSS3 D&I
  {
    id: "FUTURE",
    title: "Future Roadmap (UNFUNDED)",
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          At the moment we have far more ideas and plans for OHIF than we have
          funding to support the work. We have decided to list some of our top
          priorities here with the hope that someone reading this page will have
          resources and interest to support their development. If your team is
          interested in funding or co-funding the efforts to develop any of
          these features, please reach out to{" "}
          <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
            <OutboundLink href="mailto:contact@ohif.org">
              contact@ohif.org
            </OutboundLink>
          </span>
          .
        </p>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com",
    components: [
      {
        title: (
          <p>
            Polymorph Segmentation Representation is a concept demonstrated by
            the{" "}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://www.slicer.org/">
                3D Slicer
              </OutboundLink>
            </span>{" "}
            team in which segmentations can be automatically converted between
            representations (e.g. planar contours, closed surface, labelmap) as
            needed to support the user's desired workflow. An implementation of
            this can be found in the{" "}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://github.com/PerkLab/PolySeg">
                PolySeg
              </OutboundLink>
            </span>{" "}
            library, and more information can be found in their{" "}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://doi.org/10.1016/j.cmpb.2019.02.011">
                publication in Computer Methods and Programs in Biomedicine by
                Csaba Pinter et al.
              </OutboundLink>
            </span>{" "}
            We believe this concept should be integrated into Cornerstone at the
            library level so that it is simple to view and edit segmentations as
            labelmaps, contours, closed surfaces without maintaining independent
            representations of each segment.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Contour Editing Tools - Our goal is to provide users with the most
            intuitive segmentation tools possible. Many commercial packages
            offer clinicians contour-based editing tools, rather than pixel-wise
            paintbrushes, because they can be easier to use require less manual
            interaction. We would like to develop a set of reusable interactions
            for contours, such as livewire editing, "push-in/push-out" style
            editing, drawing using splines, and interpolating across slices.
            Aspects of these tools have been developed in other applications
            previously (i.e. XNAT-OHIF has linear interpolation across slices),
            but there is considerable room for improvement.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Legacy CornerstoneTools Parity - A handful of tools such as the
            magnify, angle, and CINE tools, have not yet been ported from legacy
            CornerstoneTools into Cornerstone3D. We would like to provide these
            tools in Cornerstone3D as well.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Cornerstone3D currently implements slab maximum intensity projection
            (MIP) by adjusting the clipping range of the camera. This is
            suboptimal but was the only available option at the time of
            implementation. Now, however, this can be implemented using clipping
            planes at the mapper level for each volume to which the MIP
            operation is being applied. The critical downside to our current
            implementation is that the camera clipping range also clips out all
            other objects, which makes it impossible for us to display surface
            models or other 3D objects in the scene. We will switch to clipping
            planes to remove this limitation, and create examples for adding
            surfaces and other VTK 3D actors, such as widgets, into
            Cornerstone3D.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Multivolume Volume Rendering: Currently, the WebGL pathway for
            VTK.js Volume Rendering, which is used by Cornerstone3D, renders
            each individual volume independently, and then overlays the
            resulting image from each pass. {""}
            <span className="phone-size:OHIF-link-ps md:OHIF-link-md">
              <OutboundLink href="https://projectweek.na-mic.org/PW33_2020_GranCanaria/Projects/VTKjsMultiVolumeRendering/">
                Previous work on this topic with the Isomics and Kitware teams
                was performed at the 33rd NAMIC Project Week in 2020.
              </OutboundLink>
            </span>{" "}
            {""}
            At the time, only partly funded so work was never completed,
            although it was nearly finished. The new WebGPU path may already
            support multiple volumes but this is not widely available yet in
            browsers, so we intendto support it in the WebGL path as well. This
            will enable displaying a segmentation inside e.g. a MIP view of a
            volume. This is critically important for visualizing results in a
            PET/CT workflow, for example.
          </p>
        ),
        jiraLink: "jira.com"
      },
      {
        title: (
          <p>
            Tool State Undo / Redo - Until now, annotation state history only
            existed in CornerstoneTools for labelmaps, despite it being a very
            common request from end users. We want to add this at the library
            level so the developer can easily undo/redo changes in the tool
            state.
          </p>
        ),
        jiraLink: "jira.com"
      }
    ]
  }
];

export default data;
