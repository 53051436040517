import React from "react";

const WithBulletPoint = ({ children, title }) => (
  <p className="flex space-x-2">
    <div className="w-3 h-3 mt-2">
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle cx="3.5" cy="3.5" r="3.5" fill="#5acce6" />
      </svg>
    </div>
    <div>
      <strong className="text-blue-100">{title}</strong> {children}
    </div>
  </p>
);

const data = [
  {
    id: "uiux",
    title: "New UI/UX (Responsive)",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          As part of our commitment to delivering an exceptional user
          experience, we are embarking on a comprehensive UX/UX overhaul to
          enhance the responsiveness and extensibility of the OHIF viewer
          component library. Our vision is to create a seamless and intuitive
          experience across a wide range of devices, from desktops to tablets
          and mobile devices.
        </p>

        <WithBulletPoint title="Component System Upgrade:">
          We are exploring the integration of a powerful component library, such
          as shadcn/UI, to build a new component system for the OHIF viewer.
          This will enable us to gradually upgrade the existing viewer
          components to a more modern and extensible architecture, paving the
          way for improved responsiveness and flexibility.
        </WithBulletPoint>

        <WithBulletPoint title="Responsive Design for Mobile Devices">
          One of our primary goals is to create a version of the OHIF viewer
          optimized for mobile devices, including tablets and smartphones. We
          recognize the increasing demand for on-the-go image analysis
          capabilities, and we are committed to delivering a user interface and
          user experience tailored specifically for smaller screens and
          touch-based interactions.
        </WithBulletPoint>

        <WithBulletPoint title="UI Element Enhancements">
          Throughout the application, we will be enhancing various UI elements
          to align with the latest UI practices and design principles. This
          includes revamping components such as the Measurement Panel, Capture
          Tool, and Study Browser.
        </WithBulletPoint>

        <WithBulletPoint title="Study Browser Improvements">
          The Study Browser is a crucial component that will receive significant
          attention. We plan to implement progressive loading bars, improved
          thumbnail previews, and fusion capabilities directly from the Study
          Browser. These enhancements will provide a more seamless and
          informative experience when navigating and managing your studies.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  {
    id: "segmentationenhancements",
    title: "Segmentation Enhancements",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          At OHIF, we have been relentlessly pushing the boundaries of
          segmentation capabilities within the Cornerstone 3D library. Our
          recent efforts have yielded significant advancements in this crucial
          domain, empowering you with more powerful and efficient segmentation
          tools.
        </p>
        <WithBulletPoint title="Stack Segmentation Support">
          Addressing a long-standing gap, we have successfully implemented stack
          segmentation capabilities. Previously, our segmentation tools were
          limited to volume segmentation. Now, you can leverage labelmaps and
          perform segmentations on all other images, such as ultrasound, DX, and
          mammograms. This development bridges the gap between your segmentation
          tools, providing a unified and comprehensive segmentation experience
          across diverse imaging modalities.
        </WithBulletPoint>
        <WithBulletPoint title="Contour Segmentation Representation">
          We have introduced a new segmentation representation called contour
          segmentation, which offers a smooth and memory-efficient alternative
          to pixel-based labelmaps. This representation is particularly
          well-suited for use cases like RT structure sets, where contours are
          commonly employed. With contour segmentation, you can leverage a range
          of editing tools, including freehand, spline, and livewire contouring
          tools. Additionally, you can edit contours by appending or removing
          points, having holes, and customize properties such as opacity, color,
          and thickness, just as you would with label maps.
        </WithBulletPoint>
        <WithBulletPoint title="Advanced Labelmap Tools">
          To enhance the label map segmentation experience, we have added
          several advanced tools, including the dynamic brush threshold. This
          powerful tool, initially introduced in 3.8 but with limited
          functionality, will soon be available in its full potential. We will
          introduce a preview stage, allowing you to visualize the results
          before accepting or rejecting them, ensuring greater control and
          accuracy in your segmentation workflows.
        </WithBulletPoint>
        <WithBulletPoint title="Seamless Segmentation Representation Conversion">
          We have made significant strides in enabling seamless conversion
          between various segmentation representations. Whether you have a
          contour segmentation, labelmap, or surface representation, you can now
          convert between these formats with ease. This conversion process is
          optimized to run within a web worker, ensuring a smooth and efficient
          experience.
        </WithBulletPoint>
      </div>
    )
  },
  {
    id: "enhancedrt",
    title: "Enhanced RT Support",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          Our commitment to providing comprehensive support for RT structure
          sets has led us to revisit and enhance our existing capabilities in
          this domain. Originally introduced in OHP 3.7, our initial RT
          structure set support did not cover all use cases and encountered
          rendering issues in certain scenarios. Recognizing these limitations,
          we have embarked on a journey to overhaul our approach and deliver a
          more robust and reliable experience.
        </p>
        <p>
          We are delighted to announce the acceptance of our set-aside funding
          proposal for a strategic collaboration with eContour, a widely used radiation therapy treatment
          planning software platform. eContour provides image-based contouring
          guidelines and has been adopted by over 45,000 radiation oncology
          professionals across 128 countries, including approximately half of
          the radiation oncologists in the United States. This collaborative
          effort aims to leverage the sophisticated visualization and
          manipulation tools of the Cornerstone3D library in OHIF to offer
          enhanced 3D contour rendering, multi-modality image support, and other
          advanced features for eContour cases. 
        </p>
        <WithBulletPoint title="Contour Segmentation Representation Migration">
          To ensure consistent and reliable rendering of RT structure sets
          across all platforms and viewports, we are migrating our RT structure
          set representation to our powerful contour segmentation
          representation. This strategic move will eliminate the rendering bugs
          and ensure that RT structure sets can be rendered seamlessly,
          regardless of the viewing environment or viewport type.
        </WithBulletPoint>
        <WithBulletPoint title="Multi-Planar Reconstruction (MPR) Integration">
          One of the highly anticipated enhancements is the integration of RT
          structure set rendering within multi-planar reconstruction (MPR)
          viewports. RT structure sets are typically defined in the acquisition
          plane, necessitating advanced image processing techniques to
          reconstruct the surface from the 2D contours and generate
          cross-sectional views for other viewports. We are implementing
          sophisticated algorithms to accomplish this feature, ensuring accurate
          and faithful representation of RT structure sets across all viewports.
        </WithBulletPoint>
        <WithBulletPoint title="Performance Optimization">
          To maintain a smooth and responsive user experience, we are exploring
          techniques to pre-cache surface cuts in the background. This proactive
          approach will minimize potential performance impacts, ensuring that
          users can seamlessly interact with RT structure sets without
          experiencing any noticeable lag or slowdown.
        </WithBulletPoint>
        <WithBulletPoint title="Integration with Parametric Maps">
          In addition to enhancing RT structure set rendering capabilities, we
          are exploring the integration and rendering of parametric maps, such
          as RTDose maps. This integration will enable seamless fusion of RT
          structure sets with critical dosimetry information, providing a
          comprehensive and holistic view of radiation therapy planning and
          delivery.
        </WithBulletPoint>
      </div>
    )
  },
  {
    id: "ohifvideoplayer",
    title: "OHIF Video Player",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          Previously we developed a cutting edge video viewport using the
          powerful Cornerstone 3D library. This new viewport is designed to
          unlock a wide range of use cases in medical imaging, offering a
          comprehensive set of features and capabilities.
        </p>
        <WithBulletPoint title="Feature Parity with Traditional Viewports">
          The new video viewport maintains feature parity with our traditional
          viewports, allowing you to seamlessly perform various operations such
          as panning, zooming, frame navigation, and key image selection.
          Additionally, you can leverage the full suite of annotation tools,
          including lens tools, bi-directional tools, and all the annotation
          tools you are familiar with from our existing viewports.
        </WithBulletPoint>
        <WithBulletPoint title="Appearance Customization">
          Beyond the core functionality, the video viewport offers advanced
          appearance customization options. You can adjust brightness, color,
          white balance, and temperature settings to tailor the display to your
          specific needs and preferences.
        </WithBulletPoint>
        <WithBulletPoint title="Segmentation Capabilities">
          One of the standout features of the new video viewport is its robust
          segmentation capabilities. You can leverage our powerful contouring
          tools, including freehand, spline, and livewire tools, to create
          precise segmentations. Additionally, the integrated labelmap
          functionality allows you to brush tools directly onto the viewports
          for each frame, providing a seamless and intuitive segmentation
          experience.
        </WithBulletPoint>
      </div>
    )
  },
  {
    id: "niftisupport",
    title: "Nifti Support",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          Building upon our recent addition of NIFTI file support in Cornerstone
          3D, we will focus on enabling comprehensive NIFTI capabilities within
          the OHIF platform. This strategic move will empower you to seamlessly
          work with NIFTI files, unlocking a wide range of use cases and
          addressing the growing demand for NIFTI support, particularly in
          multidisciplinary research teams, facilitating collaboration and
          accelerating discoveries in neuroscience, oncology, and other fields.
        </p>
        <WithBulletPoint title="Parity with DICOM Functionality">
          Our goal is to provide NIFTI files with the same level of
          functionality and capabilities that you currently enjoy with DICOM
          files. Once integrated, you will be able to leverage the full suite of
          OHIF's powerful tools and features when working with NIFTI data. This
          includes the ability to annotate, create segmentations, and export
          your work in the NIFTI format.
        </WithBulletPoint>
        <WithBulletPoint title="Seamless Integration">
          Our approach to NIFTI integration is designed to be seamless and
          intuitive. You will be able to load and interact with NIFTI files in
          the same manner as you would with DICOM files, ensuring a consistent
          and familiar user experience. This seamless integration will minimize
          the learning curve and streamline your workflows, regardless of the
          file format you are working with.
        </WithBulletPoint>
      </div>
    )
  },
  {
    id: "fhirintegration",
    title: "OHIF FHIR Integration",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          To seamlessly integrate OHIF into clinical workflows and enable
          interoperability with hospital systems, we will explore the
          integration of Fast Healthcare Interoperability Resources (FHIR)
          standards.
        </p>
        <WithBulletPoint title="Launch OHIF with Patient Context">
          With the FHIR integration, OHIF can be launched directly from hospital
          systems, such as Electronic Medical Records (EMRs), with patient
          context pre-loaded. This capability leverages the SMART on FHIR
          standard, ensuring secure authentication and authorization mechanisms.
        </WithBulletPoint>
      </div>
    )
  },

  // Simplifying installation and deployment
  {
    id: "Deploy",
    title: "Simplifying Installation and Deployment",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          Simplifying installation and usage for end users is a key focus for
          us. We understand that sometime there are strict requirements for
          systems hosted on-premises or connected to hospital resources. To
          address these challenges, we aim to create an extensible system that
          streamlines the deployment of OHIF and web-accessible imaging systems.
        </p>
        <WithBulletPoint title="New (OHIF frontend application and research PACS system):">
          This integrated solution provides a user-friendly interface for
          viewing and analyzing medical images, as well as secure storage and
          retrieval of imaging data. The combination of OHIF and a research PACS
          system ensures a comprehensive imaging infrastructure for end users.
        </WithBulletPoint>
        <WithBulletPoint title="New (User management, DICOM uploader, and log aggregation tools)">
          As part of our aim, we envision the development of a comprehensive
          system that includes a user management component designed to handle
          authentication, login/logout. Additionally, we aim to provide a secure
          DICOM uploader that simplifies the process of ingesting medical data
          while maintaining a strong focus on ensuring patient privacy through
          effective anonymization functions. To maintain compliance and ensure
          the integrity of the system, we will explore the utilization of log
          aggregation tools to monitor and analyze system logs.
        </WithBulletPoint>
        <WithBulletPoint title="New (On-premises system for query/retrieve and cloud server upload)">
          We will explore the development of an on-premises system that aims to
          enable efficient query/retrieve operations from the PACS system and
          seamless upload of data to a cloud server. By exploring this avenue,
          we aim to enhance the accessibility and availability of medical images
          for analysis and storage.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  // QMS
  {
    id: "QMS",
    title: "Quality Management System",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          To ensure a coherent vision and focused product for OHIF, we will
          implement a quality management system (QMS) to drive the development
          of the core framework. The QMS will centralize the documentation of
          product design, including user needs, design inputs and outputs,
          verification and validation tasks, and risk management. This will
          simplify integration into systems used in clinical trials and clinical
          practice, supporting compliance with regulatory standards such as FDA
          510(k) clearance, 21 CFR Part 11, HIPAA, and ISO certifications. With
          each software version release, we will publicly release the design
          documentation exported from the QMS, providing a valuable resource for
          OHIF-consuming projects and researchers targeting clinical
          translation.
        </p>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  }
];

export default data;
