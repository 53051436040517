import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import dataQ22024 from "../../roadmap/Q2-2024-details";
import dataQ22023 from "../../roadmap/Q2-2023-details";
import dataQ22022 from "../../roadmap/Q2-2022-details";
import dataQ42021 from "../../roadmap/Q4-2021-details";

import Page from "../../components/Page";
import Section from "../../components/Section";
import InlineNav from "../../components/InlineNav";
import FeaturesAccordion from "../../components/FeaturesAccordion";
import getPrevNextRoadmaps from "../../utils/getPrevNextRoadmaps";
import WithSideNav from "../../components/WithSideNav";

const roadmapId = "Q2-2024";

function RoadmapPage({ location }) {
  return (
    <Page>
      <Section>
        <WithSideNav sideNavType="community" title="Archived Roadmaps">
          <p className="mt-8 mb-8">
            See the latest roadmaps{" "}
            <a href="/roadmap" className="text-blue-100 cursor-pointer">
              here
            </a>
          </p>

          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <div>
            <div className="text-blue-100 text-3xl">Q2 2024</div>
            <div className="mb-8 mt-2">
              <StaticImage
                src={"../../images/Roadmap-2024-Q2-to-2025-Q1.png"}
                alt="roadmap"
              />
            </div>
            <div className="mb-20 mt-15 text-white">
              <FeaturesAccordion data={dataQ22024} location={location} />
            </div>
          </div>

          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <div>
            <div className="text-blue-100 text-3xl">Q2 2023</div>
            <div className="mb-8 mt-2">
              <StaticImage
                src={"../../images/Roadmap-2023-Q2-to-2024-Q1.png"}
                alt="roadmap"
              />
            </div>
            <div className="mb-20 mt-15 text-white">
              <FeaturesAccordion data={dataQ22023} location={location} />
            </div>
          </div>

          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <div>
            <div className="text-blue-100 text-3xl">Q2 2022</div>
            <div className="mb-8 mt-2">
              <StaticImage
                src={"../../images/Roadmap-2022-Q2-to-2023-Q1.png"}
                alt="roadmap"
              />
            </div>
            <div className="mb-20 mt-15 text-white">
              <FeaturesAccordion data={dataQ22022} location={location} />
            </div>
          </div>

          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <div>
            <div className="text-blue-100 text-3xl">Q4 2021</div>
            <div className="mb-8 mt-2">
              <StaticImage
                src={"../../images/Roadmap-2021-Q4-to-2022-Q3.png"}
                alt="roadmap"
              />
            </div>
            <div className="mb-20 mt-15 text-white">
              <FeaturesAccordion data={dataQ42021} location={location} />
            </div>
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default RoadmapPage;
