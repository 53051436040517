import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const WithBulletPoint = ({ children, title }) => (
  <p className="flex space-x-2">
    <div className="w-3 h-3 mt-2">
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle cx="3.5" cy="3.5" r="3.5" fill="#5acce6" />
      </svg>
    </div>
    <div>
      <strong className="text-blue-100">{title}</strong> {children}
    </div>
  </p>
);

const data = [
  {
    id: "visualization",
    title: "Visualization and Segmentation",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          We are excited to share upcoming advancements in visualization and
          segmentation. These include improved support for image analysis,
          seamless integration with MONAI and lower-level deep learning
          frameworks, and an addition of server-side rendering.
        </p>

        <WithBulletPoint title="New (WebGPU):">
          One significant move is to embrace WebGPU, a new standard in the web
          development community. WebGPU is the future of graphics and
          computation on the web, and it holds immense promise due to its
          advanced design, enhanced performance, and improved security features.
          With the help of WebGPU compute shaders, we are planning to implement
          new segmentation tools that will enable users to rapidly execute 
          image analysis algorithms on the client side.
        </WithBulletPoint>

        <WithBulletPoint title="New (Machine Learning):">
          Another area we're focusing on is the integration with common deep
          learning analysis frameworks such as MONAI and lower-level libraries
          like PyTorch and TensorFlow. We will create a MONAI mode that will
          enable users to interact with MONAI Label and MONAI Deploy. In
          addition to this, we will also document on how you can use OHIF with
          PyTorch and TensorFlow both on the client and server side, this will
          enable users to use segmentation and classification models trained
          with these frameworks. 
        </WithBulletPoint>
        <WithBulletPoint title="New (Server-side Rendering):">
          Last but not least, we plan to explore the utilization of using
          server-side rendering for large datasets. This will enable users to
          render large datasets without having to download the entire dataset to
          the client.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  // Workflow
  {
    id: "Functional",
    title: "Enhanced Functional Imaging Support",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          PET imaging plays a vital role in oncology research, clinical trials,
          and patient care, enabling disease characterization, diagnosis,
          staging, restaging, and treatment response monitoring. While OHIF
          currently supports some functional imaging functionality, we recognize
          the need to further meet the requirements of the cancer research
          community. To address this, we have planned the following
          capabilities:
        </p>
        <WithBulletPoint title="New (Image Registration):">
          Support for computing and applying image registrations across studies,
          including the ability to run registrations directly in the browser.
        </WithBulletPoint>
        <WithBulletPoint title="New (Parametric Maps):">
          Display and interaction with arbitrary floating-point volumetric
          analysis results, along with reference volumes. This functionality
          will allow users to overlay probability maps (e.,g,. AI generated)
          onto structural images and interact with them through various
          operations.
        </WithBulletPoint>
        <WithBulletPoint title="Enhancement (Radiotherapy):">
          Expanded support for radiotherapy objects, including RT Dose Maps, and
          isodose contours. By enhancing support for these objects, we aim to
          facilitate accurate treatment planning and analysis within the OHIF
          platform, ensuring comprehensive and integrated radiotherapy
          workflows.
        </WithBulletPoint>
        <WithBulletPoint title="New (Quantitative Imaging):">
          Support for quantitative molecular imaging tools, including various
          methods to evaluate SUV (e.g., lean body mass, BSA, BMI, MTV, TLG),
          parametric imaging, and texture analysis (radiomics). These tools will
          enable advanced quantitative analysis of molecular imaging data,
          empowering researchers and clinicians to gain deeper insights into
          cancer characteristics and treatment responses.
        </WithBulletPoint>
        <WithBulletPoint title="Enhancement (Interpolation):">
          Addition of additional interpolation types, such as tricubic
          interpolation, to improve the visualization of PET datasets. This
          enhancement will provide more detailed representations of PET imaging
          data, enabling better analysis and interpretation.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  // Viewer enhancemenets
  {
    id: "Viewer",
    title: "Viewer Enhancements",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          We are dedicated to enhancing efficiency and usability for end users.
          To address the challenges of customizability and extensibility, we
          introduced workflow modes in OHIF v3, enabling the creation of
          task-oriented interfaces and reporting functionality. As part of our
          ongoing efforts, we have identified the following areas for
          development:
        </p>
        <WithBulletPoint title="New: Responsive Design">
          We will introduce responsive design to optimize the user experience on
          mobile devices and vertical monitors. This enhancement will ensure
          that OHIF adapts seamlessly to different screen sizes and
          orientations, providing a consistent and user-friendly interface for
          all users.
        </WithBulletPoint>
        <WithBulletPoint title="New: Data Integration">
          To enable seamless visualization and analysis of analysis results, we
          will introduce data integration capabilities from multiple data
          sources. This functionality will allow users to combine data sources
          from multiple end points, reading from one and writing to another,
          enabling a more streamlined and efficient workflow.
        </WithBulletPoint>
        <WithBulletPoint title="New: Interactive Charting">
          We will introduce interactive charting of results, including features
          such as 4D timecourses and spectroscopy results. This enhancement will
          enable users to gain deeper insights into imaging findings by
          visualizing dynamic data in an interactive and intuitive manner.
        </WithBulletPoint>
        <WithBulletPoint title="New: Progressive Loading">
          To optimize the loading process of imaging data, we will implement
          progressive loading techniques such as HTJ2K. This can involve loading
          lossy or lower-resolution images first, or utilizing progressive
          decoding and rendering of image data as it arrives. This approach
          significantly improves the user experience by reducing the time
          required to load and display large datasets.
        </WithBulletPoint>
        <WithBulletPoint title="Enhancement: Reusable UI Component Library">
          We will expand and support a reusable UI component library, empowering
          developers to create consistent and user-friendly interfaces within
          OHIF. This enhancement will streamline the development process and
          ensure a cohesive and intuitive user experience across different
          modules and workflows.
        </WithBulletPoint>
        <WithBulletPoint title="New: Multi-Monitor Support">
          We will implement support for hanging several windows across multiple
          monitors, leveraging recent advancements in web browsers. This feature
          will empower users to enhance their productivity by efficiently
          managing and analyzing multiple imaging data sets simultaneously.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  // Simplifying installation and deployment
  {
    id: "Deploy",
    title: "Simplifying Installation and Deployment",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          Simplifying installation and usage for end users is a key focus for
          us. We understand that sometime there are strict requirements for
          systems hosted on-premises or connected to hospital resources. To
          address these challenges, we aim to create an extensible system that
          streamlines the deployment of OHIF and web-accessible imaging systems.
        </p>
        <WithBulletPoint title="New (OHIF frontend application and research PACS system):">
          This integrated solution provides a user-friendly interface for
          viewing and analyzing medical images, as well as secure storage and
          retrieval of imaging data. The combination of OHIF and a research PACS
          system ensures a comprehensive imaging infrastructure for end users.
        </WithBulletPoint>
        <WithBulletPoint title="New (User management, DICOM uploader, and log aggregation tools)">
          As part of our aim, we envision the development of a comprehensive
          system that includes a user management component designed to handle
          authentication, login/logout. Additionally, we aim to provide a secure
          DICOM uploader that simplifies the process of ingesting medical data
          while maintaining a strong focus on ensuring patient privacy through
          effective anonymization functions. To maintain compliance and ensure
          the integrity of the system, we will explore the utilization of log
          aggregation tools to monitor and analyze system logs.
        </WithBulletPoint>
        <WithBulletPoint title="New (On-premises system for query/retrieve and cloud server upload)">
          We will explore the development of an on-premises system that aims to
          enable efficient query/retrieve operations from the PACS system and
          seamless upload of data to a cloud server. By exploring this avenue,
          we aim to enhance the accessibility and availability of medical images
          for analysis and storage.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  // QMS
  {
    id: "QMS",
    title: "Quality Management System",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          To ensure a coherent vision and focused product for OHIF, we will
          implement a quality management system (QMS) to drive the development
          of the core framework. The QMS will centralize the documentation of
          product design, including user needs, design inputs and outputs,
          verification and validation tasks, and risk management. This will
          simplify integration into systems used in clinical trials and clinical
          practice, supporting compliance with regulatory standards such as FDA
          510(k) clearance, 21 CFR Part 11, HIPAA, and ISO certifications. With
          each software version release, we will publicly release the design
          documentation exported from the QMS, providing a valuable resource for
          OHIF-consuming projects and researchers targeting clinical
          translation.
        </p>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  },
  // Community
  {
    id: "Community",
    title: "Community Outreach, Support, Training, and Engagement",
    description: () => (
      <div className="flex flex-col space-y-3">
        <p>
          Community support and engagement are vital for the long-term
          sustainability of open-source projects. We aim to enhance our
          outreach, engagement, training, and support initiatives, ensuring the
          OHIF community receives the necessary assistance and resources.
        </p>
        <WithBulletPoint title="Outreach and Community Engagement">
          To enhance community engagement, we will gather feedback through focus
          groups and surveys in specific research areas. Our Request for Comment
          (RFC) process will provide a structured pathway for feature proposals,
          while regular updates to our roadmap and engagement through
          newsletters and social media will increase awareness of releases. We
          will expand our workflow Mode gallery to encourage community
          involvement.
        </WithBulletPoint>
        <WithBulletPoint title="Weekly Office Hours">
          To support our community, we will continue hosting weekly OHIF Office
          Hours for collaboration and identifying new development opportunities.
          User support will be provided through our community forum and our
          Slack channel. We will ensure comprehensive documentation for easy
          onboarding and develop training videos to address common obstacles.
          Webinars will be hosted to showcase OHIF features and provide a
          platform for Q&A sessions.
        </WithBulletPoint>
      </div>
    ),
    jiraLink: "jira.com",
    githubLink: "github.com"
  }
];

export default data;
